<template>
    <div class="loginPage">
        <div class="container mx-auto p-4">
            <div class="max-w-base mx-auto" @keypress.enter="handleLoginRequest">
                <h2 class="loginPage__title" v-text="$t('CORE.LOGIN')"/>

                <!--email-->
                <input-field
                    class="mb-3"
                    v-model.trim.lowercase="email"
                    type="email"
                    autocomplete="email"
                    :is-valid="!errorHandler.has('email')"
                    :message="errorHandler.get('email')"
                    :label="$t('CORE.EMAIL')"/>

                <!--password-->
                <input-field
                    class="mb-3"
                    v-model="password"
                    type="password"
                    :is-valid="!errorHandler.has('password')"
                    :message="errorHandler.get('password')"
                    :label="$t('CORE.PASSWORD')"/>

                <div class="px-4">
                    <btn
                        class="my-4 button__btn--primary button__btn--fullWidth"
                        :disabled="isFormProcessing"
                        v-text="$t('CORE.LOGIN')"
                        @click="handleLoginRequest"/>
                </div>
                <div class="text-center sm:px-3">
                    <router-link
                        class="text-xs underline hover:no-underline"
                        :to="{name: 'auth.register.step-1'}">
                        {{ $t('CORE.SIGN_UP') }}
                    </router-link>
                    <br>
                    <a class="text-xs underline hover:no-underline"
                       :href="resetPasswordUrl">
                        {{ $t('CORE.RESET_PASSWORD') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Btn from '@/components/Btn';
import AuthService from '@/services/Auth/AuthService';
import InputField from '@/components/forms/fields/InputField';
import ErrorResponseHandler from '@/helpers/ErrorResponseHandler';

export default {
    name: 'Login',
    metaInfo() {
        return {
            title: this.$t('META.LOGIN.TITLE'),
            meta : [
                {
                    vmid   : 'robots',
                    name   : 'robots',
                    content: 'NOODP,NOYDIR,INDEX,FOLLOW',
                },
                {
                    vmid   : 'description',
                    name   : 'description',
                    content: this.$t('META.LOGIN.DESCRIPTION'),
                },
            ],
        };
    },
    data      : function () {
        return {
            errorHandler    : new ErrorResponseHandler(),
            isFormProcessing: false,
            email           : null,
            password        : null,
        };
    },
    methods   : {
        handleLoginRequest: function () {
            this.isFormProcessing = true;

            AuthService.loginUser(this.email, this.password)
                .then(() => {
                    this.errorHandler.reset();
                    setTimeout(() => this.$router.push({name: 'app.dashboard'}), 100);
                })
                .catch(error => {
                    this.isFormProcessing = false;
                    this.errorHandler.setResponse(error.response.data);
                });
        },
    },
    computed  : {
        ...mapGetters({
            hasUserProfileLoaded: 'Account/getUserProfileLoaded',
        }),
        resetPasswordUrl: function () {
            return process.env.VUE_APP_API_URL + '/auth/forgot-password';
        },
    },
    components: {
        Btn,
        InputField,
    },
};
</script>
